import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  ButtonGroup,
  Input,
  Select,
  Text,
  useToast,
  Icon,
  Spacer,
  Flex,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  InputGroup,
  Link,
  Textarea,
  Image,
  CardBody,
  Card as ChakraCard,
  Grid,
  GridItem,
  Heading,
  CardFooter,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Divider,
  AbsoluteCenter,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  RadioGroup,
  Radio,
  VStack,
  HStack,
} from "@chakra-ui/react";

import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  formatCurrency,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";

import { FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { getActionsPermission } from "../../../helpers/api/auth";
import LoaderSimple from "../../../components/LoaderSimple";
import { useParams } from "react-router-dom";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Faturas",
  apiBase: "/bancas/configs/",
};

/**
 * COMPONENT
 */
const BaseInvoice = () => {
  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  const getLastMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return format(date, "yyyy-MM");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [closing, setClosing] = useState(getLastMonth());
  const [quota, setQuota] = useState("1");
  const [minimal, setMinimal] = useState("true");
  const [days_used_whatsapp, setDaysUsedWhatsapp] = useState();
  const [days_used_cassino, setDaysUsedCassino] = useState();
  const [invoice, setInvoice] = useState<any>({});

  const { id } = useParams();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();
  const { publish } = useEvent();
  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    setIsLoading(true);
    try {
      await api.get("/base/" + id, null)?.then((response) => {
        setData(response?.data ?? {});
      });
    } catch (e) {
      toast({
        title: "Erro ao buscar dados da base.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  // const { permission } = useSelector((state: RootState) => ({
  //   permission: state?.Auth?.user?.userData?.permission,
  // }));
  // const actions = getActionsPermission({ permission, role: "Pules" });
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const handleAdd = () => {
  //   onOpen();
  // };

  const handleGenerateInvoice = async () => {
    setIsLoading(true);
    try {
      await api
        .create("/base-invoice/", {
          idBase: Number(id),
          closing,
          quota: parseFloat(quota),
          options: {
            minimal: minimal === "true",
            days_used_whatsapp,
            days_used_cassino,
          },
        })
        ?.then((response) => {
          setInvoice(response?.data ?? {});
          toast({
            title: "Fatura gerada com sucesso.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (e) {
      toast({
        title: "Erro ao gerar fatura.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <PageTitle breadCrumbItems={[]} title={configs.title} />

      {invoice?.total ? (
        <>
          <Box padding={5} bg={"white"} borderRadius={"md"}>
            {/* Título */}
            <Heading as="h1" size="xl" textAlign="center" marginBottom={5}>
              FATURA - {invoice.base.title}
            </Heading>
            {/* Imagens */}
            <HStack spacing={5} my={12}>
              <Image src={invoice.base.logo} alt="LOGO BANCA" height={120} />
              <Spacer />
              <Image
                src="/logo_barao_invoice.png"
                alt="LOGO BARÃO"
                height={120}
              />
            </HStack>
            {/* Informações do demonstrativo */}
            <Text textAlign={"right"} my={10}>
              <b>Período:</b> de <b>{invoice?.invoice?.period?.start}</b> a{" "}
              <b>{invoice?.invoice?.period?.end}</b>
            </Text>
            {/* Tabela de Dados */}
            <TableContainer marginBottom={5} overflowX="auto">
              <Table variant="striped" size="sm" width="100%">
                <Thead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Descrição</Th>
                    <Th isNumeric>Demonstrativo</Th>
                    <Th isNumeric>Custo (%)</Th>
                    <Th isNumeric>Valor (R$)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoice?.invoice?.data?.map((item, index) => (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{item.title}</Td>
                      <Td isNumeric>
                        R$ {formatCurrency(item.demonstrativo || 0)}
                      </Td>
                      <Td isNumeric>{item.custo}</Td>
                      <Td isNumeric>R$ {formatCurrency(item.valor || 0)}</Td>
                    </Tr>
                  ))}

                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td isNumeric></Td>
                    <Td isNumeric>
                      <b> R$ {formatCurrency(invoice?.total || 0)}</b>
                    </Td>
                  </Tr>

                  {/* Continue adicionando linhas conforme necessário */}
                </Tbody>
              </Table>
            </TableContainer>
            {/* Divisor */}
            <Divider marginBottom={5} />
            {/* Rodapé */}
            <Box
              padding={3}
              borderWidth={1}
              borderRadius="md"
              backgroundColor="gray.50"
            >
              <VStack spacing={2} align="start">
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="bold">Data de vencimento:</Text>
                  <Text>{invoice?.invoice?.period?.dueDate || ""}</Text>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="bold">Total a pagar:</Text>
                  <Text>
                    <b> R$ {formatCurrency(invoice?.total || 0)}</b>
                  </Text>
                </HStack>
                <Text fontSize="sm" color="gray.500" my={0}>
                  ** Para melhor atendê-los, o serviço de cassino <b>ISENTA</b>{" "}
                  os custos de integração com <b>Whatsapp</b>.
                </Text>
                <Text fontSize="sm" color="gray.500" my={0}>
                  ** O pagamento deve ser realizado até a{" "}
                  <b>data de vencimento</b> para evitar juros adicionais.
                </Text>
                <Text fontSize="sm" color="gray.500" my={0}>
                  ** Para mais informações, entre em contato com o{" "}
                  <b>suporte ao cliente</b>.
                </Text>
              </VStack>
            </Box>
          </Box>
        </>
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {isLoading ? <LoaderSimple /> : null}
                <Row>
                  <Text fontWeight={"bold"} fontSize={"lg"}>
                    {data?.title || ""}
                  </Text>

                  <Col md={4}>
                    <FormControl mb={2}>
                      <FormLabel>Demonstrativo:</FormLabel>
                      <Input
                        type="month"
                        value={closing}
                        onChange={(e) => setClosing(e.target.value)}
                      />
                    </FormControl>

                    <Row>
                      <Col md={6}>
                        <FormControl mb={2}>
                          <FormLabel>Whatsapp:</FormLabel>
                          <Input
                            placeholder="Dias utilizados"
                            size="md"
                            type="number"
                            value={days_used_whatsapp}
                            onChange={(e) =>
                              setDaysUsedWhatsapp(e.target.value as any)
                            }
                          />
                        </FormControl>
                      </Col>
                      <Col md={6}>
                        <FormControl mb={2}>
                          <FormLabel>Cassino:</FormLabel>
                          <Input
                            placeholder="Dias utilizados"
                            size="md"
                            type="number"
                            value={days_used_cassino}
                            onChange={(e) =>
                              setDaysUsedCassino(e.target.value as any)
                            }
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={4}>
                    <FormControl mb={2}>
                      <FormLabel>Royalts:</FormLabel>
                      <RadioGroup
                        defaultValue={quota}
                        value={quota}
                        onChange={(e) => setQuota(e)}
                      >
                        <Radio value="1" mx={1}>
                          1,00%
                        </Radio>
                        <Radio value="1.5" mx={1}>
                          1,50%
                        </Radio>
                      </RadioGroup>
                    </FormControl>

                    <FormControl mb={2}>
                      <FormLabel>Mínimo:</FormLabel>
                      <RadioGroup
                        defaultValue={minimal}
                        value={minimal}
                        onChange={(e) => setMinimal(e)}
                      >
                        <Radio value="true" mx={1}>
                          SIM
                        </Radio>
                        <Radio value="false" mx={1}>
                          NÃO
                        </Radio>
                      </RadioGroup>
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Button
                      mt={4}
                      colorScheme="teal"
                      type="submit"
                      onClick={handleGenerateInvoice}
                    >
                      Gerar Fatura
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default BaseInvoice;
