import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Button as ChakraButton,
  Badge,
  Box,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Divider,
  Flex,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  TableContainer,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Table as ChakraTable,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import LoaderSimple from "../../../components/LoaderSimple";
import Select from "react-select";

/**
 * HELPERS
 */

const api = new APICore();
const configs = {
  title: "Cassino",
  path: "/base/cassino/",
  update: "/base/cassino/update/",
  apiBase: "/bancas/cassino/",
};

/**
 * COMPONENT
 */
const Cassino = () => {
  /**
   * HOOKS
   */
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const [finance, setFinance] = useState({});
  const toast = useToast();
  const [dataSelect, setDataSelect] = useState<any>(null);
  const [geralIsLoading, setGeralIsLoading] = useState(false);
  const [financeIsLoading, setFinanceIsLoading] = useState(false);
  const [formUnit, setFormUnit] = useState<string>("");
  const [formStartDate, setFormStartDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [formEndDate, setFormEndDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async () => {
    setGeralIsLoading(true);
    try {
      let dates = {
        startDate: formStartDate,
        endDate: formEndDate,
      };
      let payload = {
        ...dates,
        unit: formUnit,
      };

      await api.get("/bancas/bff/select-data", null).then((response) => {
        setDataSelect(response.data ?? []);
      });

      await api.create(configs.apiBase + "units", payload).then((response) => {
        setData(response.data ?? []);
      });
      setGeralIsLoading(false);
    } catch (e) {
      toast({
        title: "Erro ao buscar registros.",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setGeralIsLoading(false);
  };

  const handlePageLoading = useCallback(async () => {
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageInit();
    handlePageLoading();

    return () => {
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  /**
   * ACTIONS
   */

  const preparePayload = (type: string) => {
    let dates = {
      startDate: formStartDate,
      endDate: formEndDate,
    };
    let payload = {
      ...dates,
      unit: formUnit,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handleSearch = async (type: string) => {
    const payload = preparePayload(type);
    setGeralIsLoading(true);
    setFinanceIsLoading(true);

    try {
      await api.create(configs.apiBase + "units", payload).then((response) => {
        setData(response.data ?? []);
      });
      setGeralIsLoading(false);
    } catch (e) {
      toast({
        title: "Erro ao buscar registros",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setGeralIsLoading(false);
  };

  const calcAll = (field: string, format: string) => {
    const total = sumTotalValue(data?.data, field);
    if (format === "raw") {
      return total || 0;
    } else {
      return formatCurrency(total || 0);
    }
  };

  const options = [
    {
      label: "TODAS AS UNIDADES",
      options: [
        {
          label: "TODAS AS UNIDADES",
          value: "",
        },
      ],
    },
    {
      label: "Regiões",
      options:
        dataSelect?.regions?.map((item: any) => {
          return {
            label: item.name.toUpperCase(),
            value: "R:" + item.idRegion,
          };
        }) || [],
    },
    {
      label: "Promotores",
      options:
        dataSelect?.collectors?.map((item: any) => {
          return {
            label: item.fullName.toUpperCase(),
            value: "P:" + item.idUnit,
          };
        }) || [],
    },
  ];

  const columnsGeral = [
    {
      id: "idUnit",
      Header: "ID",
      accessor: "idUnit",
      sort: true,
    },
    {
      id: "fullName",
      Header: "Unidade",
      accessor: "fullName",
      sort: true,
      Cell: ({ row }: any) => {
        const item = row.original;

        enum EnumType {
          "Promotor/Unidade" = "green",
          "Repasse/Unidade" = "#c0392b",
          "Unidade" = "blue",
        }

        return (
          <>
            {item.fullName} <br />
            {item.type === "Promotor/Unidade" && (
              <Badge colorScheme={EnumType[item.type]}>{item.type}</Badge>
            )}
          </>
        );
      },
    },
    {
      id: "name",
      Header: "Região",
      accessor: "name",
      sort: false,
    },
    {
      id: "bet",
      Header: "Apostou",
      accessor: "bet",
      sort: true,
      Cell: ({ value, row }) => {
        const data = row?.original;
        const bet = data.bet;
        // const farms = data.farms;
        return (
          <>
            <span style={{ color: "green" }}>
              (+) R$ {formatCurrency(bet || 0)}
            </span>{" "}
          </>
        );
      },
    },
    {
      id: "award",
      Header: "Ganhou",
      accessor: "award",
      sort: true,
      Cell: ({ row, value }) => {
        const award = row?.original?.award;
        return (
          <span style={{ color: "#c0392b" }}>
            R$ {formatCurrency(award ?? 0)}
          </span>
        );
      },
    },
    {
      id: "total",
      Header: "Resultado",
      accessor: "total",
      sort: true,
      Cell: ({ value }) => {
        return (
          <span style={{ color: value > 0 ? "green" : "#c0392b" }}>
            R$ {formatCurrency(value || 0)}
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {geralIsLoading ? <LoaderSimple /> : null}

              <Flex direction={["column", "row"]} wrap="wrap" gap={2} my={2}>
                <ChakraButton
                  colorScheme="orange"
                  onClick={() => handleSearch("ontem")}
                >
                  Ontem
                </ChakraButton>
                <ChakraButton
                  colorScheme="yellow"
                  onClick={() => handleSearch("hoje")}
                >
                  Hoje
                </ChakraButton>
                <ChakraButton
                  colorScheme="teal"
                  onClick={() => handleSearch("7d")}
                >
                  7D
                </ChakraButton>
                <ChakraButton
                  colorScheme="blue"
                  onClick={() => handleSearch("30d")}
                >
                  30D
                </ChakraButton>
                <ChakraButton
                  colorScheme="red"
                  onClick={() => handleSearch("mes")}
                >
                  Mês
                </ChakraButton>
              </Flex>
              <Row>
                <Col sm={6}>
                  <Text fontWeight={"bold"} my={2}>
                    Selecione a unidade
                  </Text>
                  <Select
                    placeholder="Todas as unidades"
                    options={options}
                    value={options.find((opt) => opt.value === formUnit)}
                    onChange={(e: any) => setFormUnit(e.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data inicial:
                  </Text>
                  <Input
                    placeholder="Data início"
                    size="md"
                    type="date"
                    value={formStartDate}
                    onChange={(e) => setFormStartDate(e.target.value)}
                  />
                </Col>

                <Col md={3}>
                  <Text fontWeight={"bold"} my={2}>
                    Data Final:
                  </Text>
                  <Input
                    placeholder="Data fim"
                    size="md"
                    type="date"
                    value={formEndDate}
                    onChange={(e) => setFormEndDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="waves-effect waves-light"
                    style={{ backgroundColor: "#4c695c" }}
                    onClick={() => handleSearch("default")}
                  >
                    <i className="fas fa-search me-1"></i> Buscar
                  </Button>
                </Col>
              </Row>

              <Table
                isSearchable={true}
                columns={columnsGeral}
                data={(data && data?.data) ?? []}
                pageSize={5}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
                tfoot={
                  <>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <>
                          <span style={{ color: "green" }}>
                            (+) R$ {calcAll("bet", "formated")}
                          </span>{" "}
                        </>
                      </td>

                      <td style={{ color: "#c0392b" }}>
                        R$ {calcAll("award", "formated")}
                      </td>

                      <td
                        style={{
                          color:
                            calcAll("total", "raw") > 0 ? "green" : "#c0392b",
                        }}
                      >
                        R$ {calcAll("total", "formated")}
                      </td>
                    </tr>
                  </>
                }
              />
              {data?.updated && (
                <Text mt={2} color="red">
                  <i className="fas fa-info-circle me-1"></i>
                  Atualizado em: {data?.updated}
                </Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Card>
            <Card.Body>
              <TableContainer>
                <ChakraTable variant="simple">
                  <Thead>
                    <Tr>
                      <Th>PROMOTOR</Th>
                      <Th isNumeric>RESULTADO</Th>
                      <Th isNumeric>COMISSÃO (10%)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.comissions?.map((item: any) => {
                      return (
                        <Tr key={item.idUnit}>
                          <Td>{item.fullName}</Td>
                          <Td isNumeric>R$ {formatCurrency(item.total)}</Td>
                          <Td isNumeric>
                            R$ {formatCurrency(item.total * 0.1)}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Cassino;
