import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import FeatherIcon from "feather-icons-react";

// COMPONENTS
import Table from "../../../components/Table";
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  Flex,
  Button as ChakraButton,
  Input,
  Button,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  Badge,
  Icon,
  Image,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { getActionsPermission } from "../../../helpers/api/auth";
import { FaPlus, FaSearch } from "react-icons/fa";
import StatisticsWidget from "../../../components/StatisticsWidget";
import { sumTotalValue } from "../../../helpers/sum";
import UnitSelect from "../../../components/UnitSelect";

/**
 * HELPERS
 */
const api = new APICore();

/**
 * COMPONENT
 */
const Unidades = () => {
  const { permission } = useSelector((state: RootState) => ({
    permission: state?.Auth?.user?.userData?.permission,
  }));
  const actions = getActionsPermission({ permission, role: "Unidades" });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [rowSelected, setRowSelected] = useState<any>({});
  const [optionSelected, setOptionSelected] = useState<string>("link");
  const [collectorData, setCollectorData] = useState<any[]>([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [formStartDate, setFormStartDate] = useState<string>(null);
  const [formEndDate, setFormEndDate] = useState<string>(null);

  /**
   * HOOKS
   */
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  /**
   * STATES
   */
  const [data, setData] = useState<any>({});

  /**
   * LIFE CYCLE HELPERS
   */
  const fetchData = async ({ page, field, order, search, filter }) => {
    dispatch(toggleLoader(true));
    await api
      .create("/bancas/get-unit", { page, field, order, search, filter })
      .then((response) => {
        setData(response.data ?? []);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca dos dados.",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    const fetchCollectorData = async () => {
      dispatch(toggleLoader(true));

      await api
        .get("/bancas/collectors", null)
        ?.then((response) => {
          setCollectorData(response?.data ?? []);
        })
        .catch((error: any) => {
          toast({
            title: "Não foi possível realizar a busca de promotores",
            description: error,
            status: "error",
            duration: 5000,
          });
        });

      dispatch(toggleLoader(false));
    };

    fetchCollectorData();
  }, [dispatch, toast]);

  const preparePayload = (type: string) => {
    let payload = {
      startDate: formStartDate,
      endDate: formEndDate,
      idCollector: selectedUnit,
    };
    const hoje = format(new Date(), "yyyy-MM-dd");
    const decreaseDate = (qtd: number) => {
      const date = new Date();
      date.setDate(date.getDate() - qtd);
      return format(date, "yyyy-MM-dd");
    };
    if (type === "hoje") {
      payload = {
        ...payload,
        startDate: hoje,
        endDate: hoje,
      };
    } else if (type === "ontem") {
      payload = {
        ...payload,
        startDate: decreaseDate(1),
        endDate: decreaseDate(1),
      };
    } else if (type === "7d") {
      payload = {
        ...payload,
        startDate: decreaseDate(7),
        endDate: hoje,
      };
    } else if (type === "30d") {
      payload = {
        ...payload,
        startDate: decreaseDate(30),
        endDate: hoje,
      };
    } else if (type === "mes") {
      payload = {
        ...payload,
        startDate: hoje.split("-")[0] + "-" + hoje.split("-")[1] + "-01",
        endDate: hoje,
      };
    }
    setFormStartDate(payload.startDate);
    setFormEndDate(payload.endDate);
    return payload;
  };

  const handlePageLoading = useCallback(async () => {
    dispatch(toggleLoader(true));
    await fetchData({
      page: 1,
      field: null,
      order: null,
      search: null,
      filter: {},
    });
    dispatch(toggleLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnitChange = (selectedOption) => {
    setSelectedUnit(selectedOption?.value);
  };
  /**
   * ACTIONS
   */
  const handleAdd = useCallback(() => {
    navigate("/base/units/new");
  }, [navigate]);

  /**
   * TABLE ACTIONS
   */
  const ActionColumn = ({ ...props }: any) => {
    const dispatch = useDispatch<AppDispatch>();

    const object = props?.row?.values;

    /**
     * HANDLERS
     */
    const handleClickDelete = async (id: string) => {
      if (window.confirm("Deseja realmente excluir este registro?") === false)
        return;
      dispatch(toggleLoader(true));
      await api
        .delete("/bancas/unit/" + id)
        ?.then((response) => {
          toast({
            title: "Registro excluído com sucesso.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
          // Exibe um Toast de erro
          toast({
            title: "Erro ao excluir o registro.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      await fetchData({ page: 1, field: null, order: null, search: null });
      dispatch(toggleLoader(false));
    };
    return (
      <React.Fragment>
        {actions.canWrite && (
          <Link
            to={"/base/units/update/" + object?.idUnit}
            className="action-icon"
          >
            {" "}
            <i
              className="mdi mdi-square-edit-outline"
              style={{ color: "#2980b9" }}
            ></i>
          </Link>
        )}

        {actions.canDelete && (
          <Link
            to="#"
            className="action-icon"
            onClick={() => handleClickDelete(object?.idUnit)}
          >
            {" "}
            <i className="mdi mdi-delete" style={{ color: "#c0392b" }}></i>
          </Link>
        )}
      </React.Fragment>
    );
  };

  const handleOpenModal = (item: any, option: string) => {
    setRowSelected(item);
    setOptionSelected(option);
    onOpen();
  };

  const columns = [
    {
      id: "idUnit",
      Header: "ID / Data",
      accessor: "idUnit",
      sort: true,
      classes: "fw-semibold",
      Cell: ({ row }: any) => {
        const item = row.original;

        return (
          <>
            #{item.idUnit} <br />
            {format(new Date(item.createdAt), "dd/MM/yyyy")}
            <br />
            {format(new Date(item.createdAt), "HH:mm:ss")}
          </>
        );
      },
    },
    {
      id: "fullName",
      Header: "Nome completo",
      accessor: "fullName",
      sort: true,
      Cell: ({ row }: any) => {
        const item = row.original;

        enum EnumType {
          "Promotor/Unidade" = "green",
          "Repasse/Unidade" = "red",
          "Unidade" = "blue",
        }

        return (
          <>
            <span
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#000",
              }}
            >
              {item.fullName}
            </span>{" "}
            <br /> {item.identifier} <br />{" "}
            <Badge colorScheme={EnumType[item.type]}>{item.type}</Badge> <br />{" "}
          </>
        );
      },
    },
    {
      Header: "Whatsapp",
      accessor: "whatsapp",
      sort: false,
      Cell: ({ row }: any) => {
        const item = row.original;

        return (
          <Flex gap={4} direction={{ base: "column", md: "row" }}>
            <Tooltip label="Chamar cliente">
              <a
                href={`https://wa.me/+55${item?.whatsapp.replace(
                  /\D/g,
                  ""
                )}?text=${encodeURI("Olá, tudo bem?")}`}
                target="_blank"
                rel="noreferrer"
              >
                <Flex>
                  {item?.whatsapp} <Spacer />{" "}
                  <Image src="/whatsapp.png" width="24px" mx={4} />
                </Flex>
              </a>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      Header: "Cotaçao / Retorno",
      accessor: "paymentTable.name",
      sort: false,
      Cell: ({ row }: any) => {
        const item = row.original;

        return <>{item.paymentTable.name}</>;
      },
    },
    {
      Header: "Promotor",
      accessor: "collector.fullName",
      sort: false,
      Cell: ({ row }: any) => {
        const item = row.original;

        return (
          <>
            <span
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#000",
              }}
            >
              {item.collector.fullName}
            </span>
            <br /> <b>{formatCurrency(item.comission || 0)}%</b> /{" "}
            {item.comissionOn}
            <br />
            {item.idFriend && (
              <Badge colorScheme={"orange"} fontWeight={"bold"}>
                {item?.friend?.idUnit}
                {/* - 1% BRUTO */}
              </Badge>
            )}
          </>
        );
      },
    },
    {
      id: "limit",
      Header: "Limite Apostas",
      accessor: "limit",
      sort: true,
      Cell: ({ row }: any) => {
        const item = row.original;

        return <>R$ {formatCurrency(item.limit)}</>;
      },
    },
    {
      Header: "Parâmetros/Conf.",
      accessor: "type",
      sort: false,
      Cell: ({ row }: any) => {
        const item = row.original;

        return (
          <Flex direction={"column"}>
            <Flex>
              Acesso Web:
              <Spacer />
              {!!item.webAccess ? (
                <FeatherIcon icon={"check-square"} color={"#008F00"} />
              ) : (
                <FeatherIcon icon={"x-square"} color={"#FF0000"} />
              )}{" "}
            </Flex>
            <Flex>
              Milhar Brinde:
              <Spacer />
              {!!item.milharGift ? (
                <FeatherIcon icon={"check-square"} color={"#008F00"} />
              ) : (
                <FeatherIcon icon={"x-square"} color={"#FF0000"} />
              )}{" "}
            </Flex>
            <Flex>
              Chaves PIX:
              <Spacer />
              {!!item.enablePixType ? (
                <FeatherIcon icon={"check-square"} color={"#008F00"} />
              ) : (
                <FeatherIcon icon={"x-square"} color={"#FF0000"} />
              )}{" "}
            </Flex>

            <Flex>
              Saques:
              <Spacer />
              {!item.withdraw_block ? (
                <FeatherIcon icon={"check-square"} color={"#008F00"} />
              ) : (
                <FeatherIcon icon={"x-square"} color={"#FF0000"} />
              )}{" "}
            </Flex>
          </Flex>
        );
      },
    },
    {
      Header: "Link / Senha",
      accessor: "",
      sort: false,
      Cell: ({ row }: any) => {
        const item = row.original;

        return (
          <Flex align={"center"} justify={"center"} gap={3}>
            {item.type === "Promotor/Unidade" && (
              <Tooltip label="Link do promotor">
                <Flex
                  onClick={() => handleOpenModal(item, "link")}
                  cursor="pointer"
                >
                  <FeatherIcon icon={"eye"} color={"#008F00"} />
                </Flex>
              </Tooltip>
            )}
            <Tooltip label="Resetar senha">
              <Flex
                onClick={() => handleOpenModal(item, "pass")}
                cursor="pointer"
              >
                <FeatherIcon icon={"unlock"} color={"#FF0000"} />
              </Flex>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ];

  const userData = JSON.parse(
    localStorage.getItem("@Baron:user") || "{}"
  ).userData;

  const baseUrl = `${process.env.REACT_APP_APP_URL}/${userData?.base?.slug}?p=`;

  const shareText = `
      🍀🍀🍀
      Olá promotor(a), você foi cadastrado(a) na *${
        userData?.base?.title
      }*, segue abaixo os dados de acesso ao aplicativo. 🚀
      -------
      *Nome:* ${rowSelected.fullName}
      *Link*: ${baseUrl + rowSelected.slug}
      -------
      *Usuário:* ${rowSelected.identifier}
      *Senha*: ${rowSelected.isDefaultPassword ? "123456 (padrão)" : "******"}
      -------
    
      _⚠️ **Em seu primeiro acesso, será solicitado a alteração da senha padrão. ⚠️_

      _⚠️ **Responda esta mensagem para poder clicar no link. ⚠️_ 
    
      🍀 Boas apostas! 🍀
      `;

  const stats = {
    promotores: data?.stats?.promotores,
    unidades: data?.stats?.unidades,
    amigos: data?.stats?.amigos,
  };

  const filterHandleSearch = async (type: string) => {
    const filter = preparePayload(type);
    await handlePageChange({
      page: 1,
      field: null,
      order: null,
      search: null,
      filter,
    });
  };

  const handlePageChange = async ({ page, field, order, search, filter }) => {
    if (!filter) {
      filter = {
        startDate: formStartDate,
        endDate: formEndDate,
        idCollector: selectedUnit,
      };
    }
    return await fetchData({ page, field, order, search, filter });
  };

  const handleUpdatePassPlayer = async () => {
    if (
      window.confirm(
        "Deseja realmente alterar a senha da unidade " +
          rowSelected.fullName +
          " para *123456* ?"
      ) === false
    )
      return;
    dispatch(toggleLoader(true));
    await api
      .create("/bancas/unit-password/reset/", { idUnit: rowSelected.idUnit })
      ?.then((response) => {
        dispatch(toggleLoader(false));
        toast({
          title: "Senha alterada com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
        // Exibe um Toast de erro
        toast({
          title: "Erro ao alterar a senha.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />

        {optionSelected === "link" ? (
          <ModalContent py={4}>
            <ModalHeader>Link do promotor</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={"column"}>
                <Flex mt={3}>
                  <Text fontWeight={"bold"}>Link: </Text>
                  <Text ml={1}>{baseUrl + rowSelected.slug}</Text>
                </Flex>

                <Flex mt={3}>
                  <Text fontWeight={"bold"}>Nome: </Text>
                  <Text ml={1}>{rowSelected.fullName}</Text>
                </Flex>

                <Flex mt={-3}>
                  <Text fontWeight={"bold"}>Usuário: </Text>
                  <Text ml={1}>{rowSelected.identifier}</Text>
                </Flex>

                <Flex mt={-3}>
                  <Text fontWeight={"bold"}>Senha: </Text>
                  <Text ml={1}>
                    {rowSelected.isDefaultPassword ? "123456" : "******"}
                  </Text>
                </Flex>

                <ChakraButton
                  mt={2}
                  colorScheme="teal"
                  onClick={() => {
                    toast({
                      title: "Link copiado com sucesso.",
                      description: baseUrl + rowSelected.slug,
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                    navigator.clipboard.writeText(shareText);
                  }}
                >
                  Copiar link do promotor
                </ChakraButton>
              </Flex>
            </ModalBody>
          </ModalContent>
        ) : (
          <ModalContent py={4}>
            <ModalHeader>Resetar senha do jogador</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={"column"}>
                <Flex mt={3}>
                  <Text fontWeight={"bold"}>Nome: </Text>
                  <Text ml={1}>{rowSelected.fullName}</Text>
                </Flex>

                <Flex mt={-3}>
                  <Text fontWeight={"bold"}>Usuário: </Text>
                  <Text ml={1}>{rowSelected.identifier}</Text>
                </Flex>

                <Flex mt={-3}>
                  <Text fontWeight={"bold"}>Nova senha: </Text>
                  <Text ml={1}>123456</Text>
                </Flex>

                <Flex mt={-3}>
                  <Text ml={1} color="#FF0000">
                    O usuário será direcionado a fornecer uma nova senha no
                    primeiro acesso.
                  </Text>
                </Flex>

                <ChakraButton
                  mt={2}
                  colorScheme="red"
                  onClick={handleUpdatePassPlayer}
                >
                  Alterar senha do jogador
                </ChakraButton>
              </Flex>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>

      <PageTitle
        breadCrumbItems={[
          { label: "Unidades", path: "/base/units", active: true },
        ]}
        title={"Unidades"}
      />

      <Row>
        <Col>
          <Row>
            <Col md={3}>
              <StatisticsWidget
                variant="success"
                description="Total"
                stats={stats.unidades + stats.promotores}
                icon="fe-users"
              />
            </Col>
            <Col md={3}>
              <StatisticsWidget
                variant="info"
                description="Promotores"
                stats={stats.promotores}
                icon="fe-user-check"
              />
            </Col>
            <Col md={3}>
              <StatisticsWidget
                variant="info"
                description="Unidades"
                stats={stats.unidades}
                icon="fe-user"
              />
            </Col>
            <Col md={3}>
              <StatisticsWidget
                variant="warning"
                description="Amigos"
                stats={stats.amigos}
                icon="fe-share-2"
              />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <Flex width="100%">
                {actions.canCreate && (
                  <Button
                    mr={2}
                    colorScheme="yellow"
                    leftIcon={<Icon as={FaPlus} />}
                    onClick={handleAdd}
                  >
                    Adicionar Novo
                  </Button>
                )}
              </Flex>

              <Row>
                <Col md={6}>
                  <Box my={2} p={4} borderRadius={5} width={"100%"}>
                    <Flex gap={2} my={2}>
                      <ChakraButton
                        colorScheme="orange"
                        onClick={() => filterHandleSearch("ontem")}
                      >
                        Ontem
                      </ChakraButton>
                      <ChakraButton
                        colorScheme="yellow"
                        onClick={() => filterHandleSearch("hoje")}
                      >
                        Hoje
                      </ChakraButton>
                      <ChakraButton
                        colorScheme="teal"
                        onClick={() => filterHandleSearch("7d")}
                      >
                        7D
                      </ChakraButton>
                      <ChakraButton
                        colorScheme="blue"
                        onClick={() => filterHandleSearch("30d")}
                      >
                        30D
                      </ChakraButton>
                      <ChakraButton
                        colorScheme="red"
                        onClick={() => filterHandleSearch("mes")}
                      >
                        Mês
                      </ChakraButton>
                    </Flex>

                    <Flex direction={"column"} width={"100%"}>
                      <Text fontWeight={"bold"} my={0} mt={1}>
                        Promotor:
                      </Text>
                      <UnitSelect
                        data={collectorData}
                        onChange={handleUnitChange}
                      />
                    </Flex>

                    <Flex
                      direction={{ base: "column", md: "row", lg: "row" }}
                      gap={2}
                    >
                      <Flex direction={"column"} width={"100%"}>
                        <Text fontWeight={"bold"} my={0} mt={1}>
                          Data inicial:
                        </Text>
                        <Input
                          width={"100%"}
                          placeholder="Data início"
                          size="md"
                          type="date"
                          value={formStartDate}
                          onChange={(e) => setFormStartDate(e.target.value)}
                        />
                      </Flex>
                      <Spacer />
                      <Flex direction={"column"} width={"100%"}>
                        <Text fontWeight={"bold"} my={0} mt={1}>
                          Data Final:
                        </Text>
                        <Input
                          width={"100%"}
                          placeholder="Data fim"
                          size="md"
                          type="date"
                          value={formEndDate}
                          onChange={(e) => setFormEndDate(e.target.value)}
                        />
                      </Flex>
                    </Flex>
                    <Row className="my-2">
                      <Col sm={12}>
                        <Button
                          mr={2}
                          colorScheme="green"
                          leftIcon={<Icon as={FaSearch} />}
                          onClick={() => filterHandleSearch("default")}
                        >
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Box>
                </Col>
              </Row>

              <Table
                isSearchable={false} // change
                columns={columns}
                data={data.data || []}
                paginate={data.paginate}
                hideButtons={
                  permission?.title === "Gerente" ||
                  permission?.title === "Gerente Região"
                    ? true
                    : false
                }
                pageSize={12}
                isSortable={true}
                pagination={true}
                useSortBy={true}
                tableClass="table-nowrap mt-3 table-hover table-striped"
                onPageChange={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Unidades;
