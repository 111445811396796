import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Wizard, Steps, Step } from "react-albus";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
  PhoneIcon,
  AddIcon,
  WarningIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import Select from "react-select";

import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import { useRegisterForm } from "../../../hooks";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Text,
  Divider,
  AbsoluteCenter,
  Box,
  Center,
  Flex,
  Badge,
  useToast,
  useMultiStyleConfig,
  useTab,
  forwardRef,
} from "@chakra-ui/react";
import {
  formatCPF,
  formatCurrency,
  isValidPhone,
  parseCurrency,
} from "@brazilian-utils/brazilian-utils";
import FormSelectInput from "../../../components/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { toggleLoader } from "../../../redux/actions";
import LoaderTable from "../../../components/LoaderTable";
import FormSelect2Input from "../../../components/FormSelect2";

/**
 * HELPERS
 */
const api = new APICore();

/**
 * INTERFACES
 */
interface FormType {
  fullName: string;
  identifier: string;
  whatsapp: string;
  type: string;
  limit: string;
  origin: string;
  idRegion: string;
  idFriend?: string;
  observation: string;
  milharGift: string;
  enablePixType: string;
  withdraw_block: string;
  webAccess: string;
  idPaymentTable: number;
  idCollector: number;
  comission: string;
  comissionOn: string;
  isCollector?: boolean;
  link_whatsapp?: string;
  link_cassino?: string;
}

interface ApiCreationData extends FormType {
  disabledLotteries(disabledLotteries: any): unknown;
  modalityComissions: any;
}

/**
 * FORM VALIDATIONS
 */
const resolver = yupResolver(
  yup.object().shape({
    fullName: yup.string().required("Insira o nome completo"),
    identifier: yup.string().required("Insira um CPF válido"),
    whatsapp: yup.string().required("Insira um Whatsapp"),
    type: yup.string().required("Informe o tipo de unidade"),
    limit: yup.string().nullable(),
    origin: yup
      .string()
      .nullable()
      .when("type", {
        is: "Repasse/Unidade",
        then: yup.string().required("Informe a base de origem."),
      }),
    idRegion: yup.string().nullable(),
    observation: yup.string().nullable(),
    milharGift: yup.boolean().nullable(),
    enablePixType: yup.boolean().nullable(),
    withdraw_block: yup.boolean().nullable(),
    webAccess: yup.boolean().nullable(),
    idPaymentTable: yup.string().required("Insira uma tabela válida"),
    idCollector: yup.string().required("Insira um promotor válido"),
    comission: yup.string().required("Informe o valor da comissão (%)"),
    comissionOn: yup.string().required("Informe o modelo de comissão"),
    isCollector: yup.boolean().nullable(),
    link_whatsapp: yup.string().nullable(),
    link_cassino: yup.string().nullable(),
  })
);

const Form = ({ idUpdate }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  /**
   * HOOKS
   */
  const navigate = useNavigate();

  const [, updateState] = React.useState();
  const [categories, setCategories] = useState<any[]>([]);
  const [lotteries, setLotteries] = useState<any[]>([]);
  const [collectors, setCollectors] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [paymentTables, setPaymentTables] = useState<any[]>([]);
  const [isCollector, setIsCollector] = useState<boolean>(false);

  const myForm = useRegisterForm<FormType>({
    resolver: !idUpdate ? resolver : resolver,
  });

  /**
   * LIFE CYCLE HELPERS
   */
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handlePageLoadUpdate = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get("/bancas/unit/" + idUpdate, null)
      .then((response) => {
        const data: any = response?.data ?? {};
        const isCollector = data?.idCollector === data?.idUnit ? true : false;
        myForm.setValue("fullName", data?.fullName);
        myForm.setValue("identifier", data?.identifier);
        myForm.setValue("whatsapp", data?.whatsapp);
        myForm.setValue("type", data?.type);
        myForm.setValue("limit", formatCurrency(data?.limit));
        myForm.setValue("origin", data?.origin ? data?.origin?.slug : "");
        setOriginData(data?.origin);
        myForm.setValue("idRegion", data?.idRegion);
        myForm.setValue("idFriend", data?.idFriend);
        myForm.setValue("observation", data?.observation);
        myForm.setValue("milharGift", data?.milharGift);
        myForm.setValue("enablePixType", data?.enablePixType);
        myForm.setValue("withdraw_block", data?.withdraw_block);
        myForm.setValue("webAccess", data?.webAccess);
        myForm.setValue("idPaymentTable", data?.idPaymentTable);
        myForm.setValue("idCollector", data?.idCollector);
        myForm.setValue("isCollector", isCollector);
        setIsCollector(isCollector);
        myForm.setValue("comission", formatCurrency(data?.comission));
        myForm.setValue("comissionOn", data?.comissionOn);

        myForm.setValue("link_whatsapp", data?.link_whatsapp);
        myForm.setValue("link_cassino", data?.link_cassino);

        const modalityComissions: any = JSON.parse(data?.modalityComissions);
        const disabledLotteries: any = JSON.parse(data?.disabledLotteries);

        modalityComissions.map((item: any) => {
          myForm.setValue(
            `modalityComissions.${item.idModality}`,
            formatCurrency(item.value)
          );
        });

        disabledLotteries.map((item: any) => {
          myForm.setValue(
            `disabledLotteries.${item.idLottery}`,
            item.value === true ? true : false
          );
        });
      })
      .catch((error) => {
        // Exibe um Toast de erro
        toast({
          title: "Erro ao carregar registro.",
          description: error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        dispatch(toggleLoader(false));

        navigate("/base/units");
      });
    dispatch(toggleLoader(false));

    forceUpdate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUpdate]);

  const handlePageLoad = useCallback(async () => {
    dispatch(toggleLoader(true));
    await api
      .get("bancas/unit-payment-tables", null)
      ?.then((response) => {
        setPaymentTables(response?.data ?? []);
        if (!idUpdate)
          myForm.setValue("idPaymentTable", response?.data[0]?.idPaymentTable);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca das tabelas de pagamento",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    await api
      .get("/bancas/collectors", null)
      ?.then((response) => {
        setCollectors(response?.data ?? []);
        if (!idUpdate)
          myForm.setValue("idCollector", response?.data[0]?.idUnit);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de promotores",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    await api
      .get("bancas/unit-regions", null)
      ?.then((response) => {
        setRegions(response?.data ?? []);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de regiões",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    await api
      .get("/bancas/lotteries", null)
      ?.then((response) => {
        setCategories(response?.data?.categories ?? []);
        setLotteries(response?.data?.lotteries ?? []);
      })
      .catch((error: any) => {
        toast({
          title: "Não foi possível realizar a busca de loterias",
          description: error,
          status: "error",
          duration: 5000,
        });
      });

    myForm.setValue("type", "Unidade");
    myForm.setValue("comissionOn", "Bruto");

    if (idUpdate) handlePageLoadUpdate();
    dispatch(toggleLoader(false));
  }, [idUpdate, handlePageLoadUpdate]);

  const clearAllCotationFields = () => {
    paymentTables
      .filter(
        (p) => p.idPaymentTable === Number(myForm.getValues().idPaymentTable)
      )[0]
      ?.cotations.map((cotation: any) => {
        myForm.setValue(`modalityComissions.${cotation.idModality}`, "");
      });

    myForm.setValue("modalityComissions.0", "");
  };

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  /**
   * AUXILIARS
   */
  const mountApiData = useCallback((): ApiCreationData => {
    return {
      ...myForm.getValues(),
    };
  }, [myForm]);

  const handleIsCollectorChange = (e: any) => {
    setIsCollector(e.target.checked);
    myForm.setValue("isCollector", e.target.checked);
  };

  const watchChangeType = () => {
    if (myForm.getValues().type === "Promotor/Unidade") {
      myForm.setValue("isCollector", true);
      setIsCollector(true);
    } else {
      myForm.setValue("isCollector", false);
      setIsCollector(false);
    }
  };

  useEffect(() => {
    if (isCollector) {
      myForm.setValue("idCollector", 0);
    } else {
      myForm.resetField("idCollector");
    }
  }, [isCollector]);

  /**
   * ACTIONS
   */
  const handleSubmit = useCallback(async () => {
    dispatch(toggleLoader(true));
    const dataForm = mountApiData();

    if (!validateWhatsapp(dataForm.whatsapp)) return;

    const cotationsBonusWithIndex = Object.keys(
      dataForm.modalityComissions
    ).map((key) => {
      return {
        idModality: Number(key),
        value: dataForm.modalityComissions[key]
          ? parseCurrency(dataForm.modalityComissions[key])
          : null,
      };
    });

    // const lotterysWithIndex = Object.keys(dataForm.disabledLotteries).map(
    //   (key) => {
    //     return {
    //       idLottery: Number(key),
    //       value: dataForm.disabledLotteries[key],
    //     };
    //   }
    // );

    // const disabledLotteries = lotterysWithIndex.filter(
    //   (lot) => lot.value === false
    // );
    const modalityComissions = cotationsBonusWithIndex.filter(
      (cot) => cot.value !== null
    );

    const payload = {
      ...dataForm,
      limit: parseCurrency(dataForm.limit),
      comission: parseCurrency(dataForm.comission),
      // disabledLotteries,
      modalityComissions,
    };

    if (!idUpdate)
      return await api
        .create("/bancas/unit", payload)
        .then(() => {
          dispatch(toggleLoader(false));
          navigate("/base/units");
          toast({
            title: "Cadastrar unidade.",
            description: "Sucesso ao cadastrar unidade.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error: any) => {
          dispatch(toggleLoader(false));
          toast({
            title: "Erro ao cadastrar unidade",
            description: error,
            status: "error",
            duration: 5000,
          });
        });

    return await api
      .update("/bancas/unit/" + idUpdate, payload)
      .then(() => {
        dispatch(toggleLoader(false));
        navigate("/base/units");
        toast({
          title: "Atualizar unidade.",
          description: "Sucesso ao atualizar unidade.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error: any) => {
        dispatch(toggleLoader(false));
        toast({
          title: "Erro ao atualizar unidade",
          description: error,
          status: "error",
          duration: 5000,
        });
      });
  }, [idUpdate, mountApiData, navigate]);

  const [originData, setOriginData] = useState<any>({});

  let interval: NodeJS.Timeout;

  const handleOriginChangeWithInterval = async (slug: string) => {
    clearTimeout(interval);

    interval = setTimeout(async () => {
      await handleOriginChange(slug);
      clearInterval(interval);
    }, 100);
  };

  const handleOriginChange = async (slug: string) => {
    setOriginData({});

    await api.get("/bancas/repasse/" + slug, null).then((response) => {
      setOriginData(response?.data ?? {});
    });
  };

  window.addEventListener(
    "keydown",
    function (e) {
      if (
        e.keyIdentifier == "U+000A" ||
        e.keyIdentifier == "Enter" ||
        e.keyCode == 13
      ) {
        if (e.target.nodeName == "INPUT" && e.target.type == "text") {
          e.preventDefault();
          return false;
        }
      }
    },
    true
  );

  const validateWhatsapp = (value: string) => {
    if (!isValidPhone(value)) {
      toast({
        title: "Erro",
        description: "Número de whatsapp inválido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  let optionsRepasse = [
    { label: "Unidade", value: "Unidade" },
    {
      label: "Promotor/Unidade",
      value: "Promotor/Unidade",
    },
  ];

  const { userData } = useSelector((state: RootState) => ({
    userData: state?.Auth?.user?.userData,
  }));

  if (
    userData.base.slug === "barao" ||
    userData.base.slug === "teste-loterias"
  ) {
    optionsRepasse.push({ label: "Repasse/Unidade", value: "Repasse/Unidade" });
  }

  // const [selectedValue, setSelectedValue] = useState(null);
  // const [errors, setErrors] = useState({});

  // useEffect(() => {
  //   // Aqui você pode verificar se o valor selecionado é válido e, se não for, adicionar um erro ao estado de erros
  //   if (selectedValue === null || selectedValue === "") {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       idCollector: "Este campo é obrigatório",
  //     }));
  //   } else {
  //     setErrors((prevErrors) => {
  //       const { idCollector, ...rest } = prevErrors;
  //       return rest;
  //     });
  //   }
  // }, [selectedValue]);

  return (
    <Card>
      <Card.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            myForm.handleSubmit(handleSubmit)();
          }}
        >
          <LoaderTable />
          <Tabs variant="enclosed">
            <TabList
              width={"100%"}
              display="flex"
              flexDirection={{ md: "row", base: "column" }}
            >
              <Tab>Dados cadastrais</Tab>
              <Tab>Promotor(a)</Tab>
              <Tab>Cotação</Tab>
            </TabList>
            <TabPanels>
              <TabPanel className={"pt-4"}>
                <Row>
                  <Col md={6}>
                    <FormInput
                      autoFocus
                      key="fullName"
                      name="fullName"
                      type="text"
                      label="Nome Completo"
                      placeholder="Digite o nome completo"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                    />

                    <FormInput
                      key="whatsapp"
                      name="whatsapp"
                      type="text"
                      label="Whatsapp (com DDD)"
                      placeholder="Informe um número de Whatsapp"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      maxLength={16}
                      onChange={(e: any) => {
                        return (e.target.value = e.target.value
                          .toString()
                          .replace(/\D/g, "")
                          .replace(
                            /(\d{2})(\d{1})(\d{4})(\d{4})/,
                            "($1) $2 $3-$4"
                          ));
                      }}
                    />

                    <FormInput
                      key="limit"
                      name="limit"
                      type="text"
                      label="Limite de Saldo (R$)"
                      placeholder="Informe um limite de saldo"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultValue={"0,00"}
                      onChange={(e: any) => {
                        return (e.target.value = formatCurrency(
                          parseCurrency(e.target.value)
                        ));
                      }}
                    />

                    <FormInput
                      key="observation"
                      name="observation"
                      type="textarea"
                      label="Observações"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      rows={"6"}
                    />
                  </Col>

                  <Col md={6}>
                    <FormInput
                      key="identifier"
                      name="identifier"
                      type="text"
                      label="CPF da unidade"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      onChange={(e: any) => {
                        return (e.target.value = formatCPF(e.target.value));
                      }}
                    />

                    <FormSelectInput
                      name="type"
                      label="Selecione um tipo de usuário"
                      multiple={false}
                      labelKey={"label"}
                      containerClass={"mb-3"}
                      resetField={myForm.resetField}
                      getValues={myForm.getValues}
                      setValue={myForm.setValue}
                      register={myForm.register}
                      errors={myForm.errors}
                      onChange={watchChangeType}
                      options={optionsRepasse}
                    />

                    {myForm.getValues().type === "Repasse/Unidade" && (
                      <>
                        <FormInput
                          name="origin"
                          type="text"
                          label="Base de origem"
                          placeholder="Digite o código da base de origem"
                          containerClass={"mb-3"}
                          register={myForm.register}
                          control={myForm.control}
                          errors={myForm.errors}
                          onChange={(e: any) => {
                            handleOriginChangeWithInterval(e.target.value);
                          }}
                        />
                        {originData?.base && (
                          <Badge colorScheme="green" mt={-12}>
                            {originData.base.title}
                          </Badge>
                        )}
                        {!originData?.base && (
                          <Badge colorScheme="red" mt={-12}>
                            BASE NÃO ENCONTRADA
                          </Badge>
                        )}
                      </>
                    )}

                    <FormSelectInput
                      name="idRegion"
                      label="Selecione uma região"
                      multiple={false}
                      labelKey={"label"}
                      containerClass={"mb-3"}
                      resetField={myForm.resetField}
                      getValues={myForm.getValues}
                      setValue={myForm.setValue}
                      register={myForm.register}
                      errors={myForm.errors}
                      options={regions.map((item: any) => {
                        return {
                          label: item.name,
                          value: item.idRegion,
                        };
                      })}
                    />

                    <FormInput
                      key="webAccess"
                      name="webAccess"
                      type="checkbox"
                      label="Acesso Online"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultChecked={true}
                      rows={"6"}
                    />
                    <FormInput
                      key="milharGift"
                      name="milharGift"
                      type="checkbox"
                      label="Milhar Brinde"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultChecked={true}
                      rows={"6"}
                    />
                    <FormInput
                      key="enablePixType"
                      name="enablePixType"
                      type="checkbox"
                      label="Chaves PIX (Habilita saque em qualquer chave)"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultChecked={true}
                      rows={"6"}
                    />

                    <FormInput
                      key="withdraw_block"
                      name="withdraw_block"
                      type="checkbox"
                      label="Bloquear saques"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultChecked={false}
                      rows={"6"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {!idUpdate && (
                      <Box border={"1px dashed red"} p={2} borderRadius={12}>
                        <Text>
                          Lembre-se de avisar a Unidade que sua senha será
                          gerada pelo sistema.
                        </Text>
                        <Text>
                          SENHA: <b>123456</b>
                        </Text>
                        <Text>
                          No primeiro acesso a Unidade será obrigada a alterar
                          sua senha.
                        </Text>
                      </Box>
                    )}
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel className={"pt-4"} key="promotor">
                <Row>
                  <Col md={12}>
                    {myForm.getValues().type === "Promotor/Unidade" && (
                      <FormInput
                        key="isCollector"
                        name="isCollector"
                        type="checkbox"
                        label="Deseja que o promotor seja a unidade que será criada?"
                        containerClass={"mb-3"}
                        // defaultChecked={myForm.getValues().isCollector || true}
                        onChange={handleIsCollectorChange}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {!isCollector && (
                      <FormSelect2Input
                        name="idCollector"
                        label="Selecione um promotor"
                        multiple={false}
                        labelKey={"label"}
                        containerClass={"mb-3"}
                        resetField={myForm.resetField}
                        getValues={myForm.getValues}
                        setValue={myForm.setValue}
                        register={myForm.register}
                        errors={myForm.errors}
                        options={collectors.map((item: any) => {
                          return {
                            label: item.fullName,
                            value: item.idUnit,
                          };
                        })}
                      />
                    )}

                    <FormSelectInput
                      name="comissionOn"
                      label="Comissão sobre:"
                      multiple={false}
                      labelKey={"label"}
                      containerClass={"mb-3"}
                      resetField={myForm.resetField}
                      getValues={myForm.getValues}
                      setValue={myForm.setValue}
                      register={myForm.register}
                      errors={myForm.errors}
                      options={[
                        {
                          label: "Bruto",
                          value: "Bruto",
                        },
                        // { label: "Líquido", value: "Líquido" },
                      ]}
                    />

                    <FormInput
                      key="comission"
                      name="comission"
                      type="text"
                      label="Comissão *(%)"
                      className="form-control"
                      containerClass={"mb-3"}
                      register={myForm.register}
                      control={myForm.control}
                      errors={myForm.errors}
                      defaultValue={"0,00"}
                      onChange={(e: any) => {
                        return (e.target.value = formatCurrency(
                          parseCurrency(e.target.value)
                        ));
                      }}
                    />
                    {idUpdate && (
                      <FormInput
                        key="idFriend"
                        name="idFriend"
                        type="text"
                        label="Rede de amigos:"
                        className="form-control"
                        containerClass={"mb-3"}
                        register={myForm.register}
                        control={myForm.control}
                        errors={myForm.errors}
                      />
                    )}
                  </Col>

                  <Col md={6}>
                    {myForm.getValues().type === "Promotor/Unidade" && (
                      <>
                        <FormInput
                          key="link_whatsapp"
                          name="link_whatsapp"
                          type="text"
                          label="Link Whatsapp"
                          placeholder="Digite o link do whatsapp (promotor)"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={myForm.register}
                          control={myForm.control}
                          errors={myForm.errors}
                        />

                        <FormInput
                          key="link_cassino"
                          name="link_cassino"
                          type="text"
                          label="Link Cassino"
                          placeholder="Digite o link do cassino (promotor)"
                          className="form-control"
                          containerClass={"mb-3"}
                          register={myForm.register}
                          control={myForm.control}
                          errors={myForm.errors}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel className={"pt-4"} id="cotação">
                <Row>
                  <Col md={6}>
                    <FormSelectInput
                      name="idPaymentTable"
                      label="Tabela de pagamento"
                      multiple={false}
                      labelKey={"label"}
                      containerClass={"mb-3"}
                      resetField={myForm.resetField}
                      getValues={myForm.getValues}
                      setValue={myForm.setValue}
                      register={myForm.register}
                      errors={myForm.errors}
                      options={paymentTables.map((item: any) => {
                        return {
                          label: item.name,
                          value: item.idPaymentTable,
                        };
                      })}
                      onChange={clearAllCotationFields}
                    />
                  </Col>
                </Row>
                {myForm.getValues().idPaymentTable && (
                  <Row>
                    <Col md={12}>
                      <Box position="relative" padding="1">
                        <Divider />
                        <AbsoluteCenter bg="white" px="4" fontWeight={"bold"}>
                          BÔNUS POR MODALIDADE
                        </AbsoluteCenter>
                      </Box>
                      <Center>
                        <TableContainer width={"100%"}>
                          <Table size="sm">
                            <Thead>
                              <Tr>
                                <Th>#</Th>
                                <Th>Modalidade</Th>
                                <Th w={50}>Cotação</Th>
                                <Th w={200}>Comissão (%)</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr _hover={{ bg: "gray.200" }}>
                                <Td fontWeight={"bold"}>TODAS</Td>
                                <Td fontWeight={"bold"}>TODAS</Td>
                                <Td fontWeight={"bold"} isNumeric>
                                  #
                                </Td>
                                <Td>
                                  <Flex>
                                    <FormInput
                                      key={`modalityComissions.0`}
                                      name={`modalityComissions.0`}
                                      type="text"
                                      register={myForm.register}
                                      control={myForm.control}
                                      errors={myForm.errors}
                                      maxLength={5}
                                      onChange={(e: any) => {
                                        return (e.target.value = formatCurrency(
                                          parseCurrency(e.target.value)
                                        ));
                                      }}
                                    />
                                    <SmallCloseIcon
                                      ml={1}
                                      boxSize={4}
                                      p={1}
                                      cursor={"pointer"}
                                      backgroundColor={"gray.50"}
                                      height={"auto"}
                                      width={8}
                                      onClick={() => {
                                        myForm.setValue(
                                          "modalityComissions.0",
                                          ""
                                        );
                                      }}
                                    />
                                  </Flex>
                                </Td>
                              </Tr>

                              {paymentTables
                                .filter(
                                  (p) =>
                                    p.idPaymentTable ===
                                    Number(myForm.getValues().idPaymentTable)
                                )[0]
                                ?.cotations.map((cotation: any) => {
                                  return (
                                    <Tr _hover={{ bg: "gray.200" }}>
                                      <Td>{cotation.modality.code}</Td>
                                      <Td>{cotation.modality.name}</Td>
                                      <Td isNumeric>
                                        R$ {formatCurrency(cotation.value)}
                                      </Td>
                                      <Td>
                                        <Flex>
                                          <FormInput
                                            key={`modalityComissions.${cotation.idModality}`}
                                            name={`modalityComissions.${cotation.idModality}`}
                                            type="text"
                                            maxLength={5}
                                            register={myForm.register}
                                            control={myForm.control}
                                            errors={myForm.errors}
                                            onChange={(e: any) => {
                                              return (e.target.value =
                                                formatCurrency(
                                                  parseCurrency(e.target.value)
                                                ));
                                            }}
                                          />
                                          <SmallCloseIcon
                                            ml={1}
                                            boxSize={4}
                                            p={1}
                                            cursor={"pointer"}
                                            backgroundColor={"gray.50"}
                                            height={"auto"}
                                            width={8}
                                            onClick={() => {
                                              myForm.setValue(
                                                `modalityComissions.${cotation.idModality}`,
                                                ""
                                              );
                                            }}
                                          />
                                        </Flex>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Center>
                    </Col>
                  </Row>
                )}
              </TabPanel>
              {/* <TabPanel className={"pt-4"} id="loterias">
                {categories.map((category: any) => {
                  return (
                    <div key={category.idCategory}>
                      <Box position="relative" padding="1">
                        <Divider />
                        <AbsoluteCenter bg="white" px="4" fontWeight={"bold"}>
                          {category.title}
                        </AbsoluteCenter>
                      </Box>
                      <Center>
                        <TableContainer
                          width={{ lg: "50%", md: "70%", sm: "100%" }}
                        >
                          <Table size="sm">
                            <Thead>
                              <Tr>
                                <Th>Loteria</Th>
                                <Th w={50}>Código</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {lotteries
                                .filter(
                                  (lot: any) =>
                                    lot.idCategory === category.idCategory
                                )
                                .map((lottery: any, i: number) => {
                                  return (
                                    <Tr>
                                      <Td>
                                        <FormInput
                                          key={`disabledLotteries.${lottery.idLottery}`}
                                          name={`disabledLotteries.${lottery.idLottery}`}
                                          type="checkbox"
                                          label={lottery.title}
                                          register={myForm.register}
                                          control={myForm.control}
                                          errors={myForm.errors}
                                          defaultChecked={true}
                                          containerClass={"py-1 "}
                                          labelClassName={"p-2"}
                                        />
                                      </Td>
                                      <Td isNumeric>{lottery.code}</Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Center>
                    </div>
                  );
                })}
              </TabPanel> */}
            </TabPanels>
          </Tabs>
          <ul className="list-inline wizard mb-0">
            <li className="next list-inline-item float-end">
              <Button type="submit" variant="success">
                Salvar Registro
              </Button>
            </li>
          </ul>
        </form>
      </Card.Body>
    </Card>
  );
};

const UnidadesCreate = () => {
  const { id } = useParams();
  const [idUpdate] = useState<string | undefined>(id);

  return (
    <React.Fragment>
      <PageTitle
        title={!idUpdate ? "Cadastrar Unidade" : "Atualizar Unidade"}
        breadCrumbItems={[
          { label: "Unidades", path: "/base/units " },
          {
            active: true,
            label: !idUpdate ? "Cadastrar" : "Atualizar",
            path: !idUpdate ? "/base/units/new" : "/base/units/update",
          },
        ]}
      />

      <Row>
        <Col lg={12}>
          <Form idUpdate={idUpdate} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UnidadesCreate;
